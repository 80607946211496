import axios from "axios";
import cfg from "../cfg";

let config = {
  api_host: cfg.API_HOST,
};

const defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("access_token") || "",
};

export default axios.create({
  baseURL: `${config["api_host"]}`,
  headers: defaultHeaders,
});
