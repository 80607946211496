import { Box, Typography } from "@material-ui/core";
import React from "react";

const initialData = {
  tasks: [
    {
      id: 1,
      title: "some title",
      descirption: "some descirption",
    },
    {
      id: 2,
      title: "some title",
      descirption: "some descirption",
    },
    {
      id: 3,
      title: "some title",
      descirption: "some descirption",
    },
    {
      id: 4,
      title: "some title",
      descirption: "some descirption",
    },
  ],
  columns: [
    {
      id: 1,
      title: "To Do",
      tasks: [1, 2, 3],
    },
  ],
  columnOrder: [1],
};

function Tasks() {
  return initialData.columnOrder.map((columnId) => {
    const column = initialData.columns;
  });
}

export default Tasks;
