import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import ImportantDevicesOutlinedIcon from "@material-ui/icons/ImportantDevicesOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";

export const menu = [
  { title: "Dashboard", icon: <DashboardOutlinedIcon />, path: "/dashboard" },
  {
    title: "My Tasks",
    icon: <AssignmentTurnedInOutlinedIcon />,
    path: "/tasks",
  },
  {
    title: "Projects",
    icon: <ImportantDevicesOutlinedIcon />,
    path: "/projects",
  },
  {
    title: "Users",
    icon: <GroupOutlinedIcon />,
    path: "/users",
  },
];
