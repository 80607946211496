import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import { theme } from "./styles/theme";
import { ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import ProjectView from "pages/ProjectView";
import { HotKeys } from "react-hotkeys";
import Users from "components/Users";

function App() {
  const keyMap = {
    OPEN_LABELS: ["l"],
    OPEN_MEMBERS: ["m"],
  };

  return (
    <HotKeys keyMap={keyMap}>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>

              <Route path="/">
                {localStorage.getItem("access_token") ? (
                  <Layout />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </HotKeys>
  );
}

export default App;
