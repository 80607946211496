import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createProject } from "api/ApiService";
import React, { useState } from "react";
import { CustomTextField } from "../../FormFields/CustomTextField";
import { useSnackbar } from "notistack";

export default function CreateProject({ open, setOpen, getData }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };
  const [state, setState] = useState({
    name: "",
    startDate: null,
    estimatedEndDate: "",
    projectLead: "",
    description: "",
    perHourCost: "",
  });

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    createProject(state)
      .then(() => {
        enqueueSnackbar("Project created successfully", { variant: "success" });
        setOpen(false);
        getData();
      })
      .catch((err) => {
        enqueueSnackbar("Project created successfully", { variant: "error" });
      });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Create Project</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  name="name"
                  label="Name"
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomTextField
                  name="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomTextField
                  name="estimatedEndDate"
                  label="Due Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomTextField
                  name="lead"
                  label="Lead"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomTextField
                  name="perHourCost"
                  label="Per Hour Cost"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="description"
                  label="Description"
                  multiline
                  rows={5}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
