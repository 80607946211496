import { createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#017593",
    },
    secondary: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: "nunito_regular",
  },
  MuiTypography: {
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",

      fontWeight: "lighter",
    },
    h1: {
      fontSize: "34px",
      fontWeight: "normal",
    },
    h2: {
      fontSize: "30px",
      fontWeight: "normal",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "normal",
    },
    h4: {
      fontSize: "20px",
      fontWeight: "normal",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "normal",
    },
    subtitle1: {
      fontSize: "18px",
    },
    subtitle2: {
      fontSize: "20px",
    },
  },
});
