import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { CustomTextField } from "components/FormFields/CustomTextField";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Type({ anchorEl, setAnchorEl, state, setState }) {
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setState({ ...state, type: e.target.value });
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box px={3}>
          <Box my={2}>
            <Typography
              variant="subtitle1"
              style={{ textTransform: "uppercase" }}
            >
              Choose Type
            </Typography>
            <RadioGroup value={state.type} onChange={handleChange}>
              <FormControlLabel value="task" control={<Radio />} label="Task" />
              <FormControlLabel value="bug" control={<Radio />} label="Bug" />
            </RadioGroup>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
