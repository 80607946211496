import { Box, Toolbar } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import Appbar from "./Appbar";
import CustomDrawer from "./Drawer";
import Routes from "./Routes";

export default function Layout() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <Appbar open={open} handleDrawerOpen={handleDrawerOpen} />
      <CustomDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <Box flexGrow={1} p={3}>
        <Toolbar />
        <Routes />
      </Box>
    </Box>
  );
}
