import { CircularProgress, Fab, Grid, makeStyles } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { getMembers } from "api/ApiService";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateUser from "./components/CreateUser";
import UserCard from "./components/UserCard";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function Users() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState({ data: [], loading: false });
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setUsers({ ...users, loading: true });
    try {
      let res = await getMembers();
      setUsers({ data: res.data, loading: false });
    } catch (err) {
      console.log(`cannot get users due to ${err}`);
    }
  }
  if (users.loading) return <CircularProgress />;

  return (
    <>
      <Grid container spacing={3}>
        {users.data.map((item) => (
          <Grid item lg={4} xs={12} md={6}>
            <UserCard data={item} getData={getData} />
          </Grid>
        ))}
      </Grid>

      <Fab
        color="primary"
        className={classes.fab}
        aria-label="add"
        onClick={() => setOpen(true)}
      >
        <AddOutlinedIcon />
      </Fab>
      <CreateUser open={open} setOpen={setOpen} getData={getData} />
    </>
  );
}

export default Users;
