import { TextField } from "@material-ui/core";

export function CustomTextField({ label, name, type = "text", ...props }) {
  return (
    <TextField
      margin="dense"
      label={label}
      type={type}
      name={name}
      fullWidth
      variant="outlined"
      {...props}
      onChange={props.onChange}
    />
  );
}
