import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { Button, Chip, IconButton, Typography } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { SettingsPowerRounded } from "@material-ui/icons";
import EditTask from "./EditTask";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "30px",
  },
  container: {
    maxHeight: 440,
  },
});

export default function TasksList({ data, getData }) {
  const [taskData, setTaskData] = useState({ data: null, loading: true });
  const [open, setOpen] = React.useState(false);

  const columns = [
    { id: "title", label: "Title", minWidth: 170, format: (value) => value },
    {
      id: "description",
      label: "Description",
      minWidth: 170,
      format: (value) => value,
    },
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "dueDate",
      label: "dueDate",
      minWidth: 170,
      align: "right",
      format: (value) => moment(value).format("MMM DD, Y"),
    },
    {
      id: "points",
      label: "Points",
      minWidth: 100,
      align: "right",
      format: (value) => value,
    },
    {
      id: "labels",
      label: "Labels",
      minWidth: 100,
      align: "right",
      format: (value) => {
        return value.map((item) => {
          return <Chip color="primary" label={item} />;
        });
      },
    },
    {
      id: "id",
      label: "Actions",
      minWidth: 180,
      align: "right",
      format: (value) => {
        return (
          <>
            <IconButton
              onClick={() => {
                let task = rows.filter((item, index) => item.id === value);
                console.log(task[0]);
                setTaskData({ ...taskData, data: task[0] });
                console.log(taskData);
                setOpen(true);
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const rows = data;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format(value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {taskData.data ? (
        <EditTask open={open} setOpen={setOpen} task={taskData.data} />
      ) : null}

      {/* <EditTask open={open} setOpen={setOpen} task={taskData.data} /> */}
    </>
  );
}
