import http from "utils/http";

function getProjects() {
  return http.get("/projects");
}

function createProject(data) {
  return http.post("/projects", data);
}

function getProjectData(slug) {
  return http.get(`/projects/${slug}`);
}

function getMembers() {
  return http.get(`/users`);
}

function createTask(data) {
  return http.post("/tasks", data);
}

function listTasks(projectId) {
  return http.get(`/tasks/${projectId}`);
}

function updateTask(taskId, data) {
  return http.patch(`tasks/${taskId}`, data);
}

function getMyTasks(userId) {
  return http.get(`tasks/users/${userId}`);
}

function createUser(data) {
  return http.post("users", data);
}

function updateUser(userId, data) {
  return http.put(`users/${userId}`, data);
}

function login(data) {
  return http.post("auth/login", data);
}

export {
  getProjects,
  createProject,
  getProjectData,
  getMembers,
  createTask,
  listTasks,
  updateTask,
  getMyTasks,
  createUser,
  updateUser,
  login,
};
