import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Avatar, Box, Chip } from "@material-ui/core";
import moment from "moment";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,
    padding: "10px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatarStyles: {
    textTransform: "uppercase",
  },
});

export default function Task({ task }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root} variant="outlined">
      {task.labels ? (
        <Box
          component="span"
          style={{
            border: "none",
          }}
        >
          {task.labels.map((label) => {
            return (
              <Box
                component="span"
                style={{
                  padding: "0 4px 0 4px",
                  margin: "0 4px 0 4px",
                  color: "whitesmoke",
                  fontWeight: "bold",
                  backgroundColor: "#2196f3",
                  borderRadius: "4px",
                }}
              >
                {label}
              </Box>
            );
          })}
        </Box>
      ) : null}

      <Typography
        variant="subtitle1"
        component="p"
        style={{ lineHeight: "normal" }}
      >
        {task.title}
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box>{moment(task.dueDate).format("MMM DD, Y")}</Box>
        <DescriptionOutlinedIcon title="description" />
      </Box>
    </Card>
  );
}
