import { Box, Fab, Grid, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getProjectData, listTasks } from "api/ApiService";
import ProjectCard from "components/Projects/components/ProjectCard";
import { useSnackbar } from "notistack";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CreateTask from "components/Tasks/components/CreateTask";
import TasksList from "components/Tasks/components/TasksList";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ProjectView() {
  const history = useHistory();
  const match = useRouteMatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [project, setProject] = useState({ data: [], loading: false });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tasks, setTasks] = React.useState({ data: [], loading: false });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setProject({ ...project, loading: true });
    try {
      let res = await getProjectData(match.params.slug);
      let taskRes = await listTasks(res.data.id);
      setProject({ data: res.data, loading: false });
      setTasks({ data: taskRes.data, loading: false });
    } catch (err) {
      enqueueSnackbar(
        `cannot get the project information due to server error ${err.message}`,
        { variant: "error" }
      );
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box component="div" display="inline">
          <Fab
            color="inherit"
            aria-label="back"
            onClick={() => {
              history.push("/projects");
            }}
          >
            <ArrowBackIcon />
          </Fab>
        </Box>
        <Box component="div" display="inline" p={2}>
          <Typography variant="h5"> Projects / {project.data.name}</Typography>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box p={2}>
            <ProjectCard data={project.data} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            p={2}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            alignContent="center"
          >
            <Typography>Graph here..</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TasksList data={tasks.data} />
        </Grid>
      </Grid>

      <Fab
        color="primary"
        className={classes.fab}
        aria-label="add"
        onClick={() => setOpen(true)}
      >
        <AddOutlinedIcon />
      </Fab>
      <CreateTask open={open} setOpen={setOpen} project={project.data} />
    </>
  );
}

export default ProjectView;
