import React from "react";
import Dashboard from "../Dashboard";
import Tasks from "../Tasks";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Projects from "../Projects";
import ProjectView from "pages/ProjectView";
import TaskView from "pages/TaskView";
import Users from "components/Users";

function Routes() {
  return (
    <Switch>
      <Route path="/tasks">
        <TaskView />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/projects/:slug">
        <ProjectView />
      </Route>
      <Route path="/projects">
        <Projects />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
}

export default Routes;
