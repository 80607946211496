import { Box, Divider, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { React } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function ProjectCard({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography
                    variant="body2"
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {moment(data?.startDate).format("MMM DD, Y")}
                  </Typography>

                  <Typography
                    variant="h5"
                    className={classes.title}
                    gutterBottom
                  >
                    {data?.name}
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <StarBorderIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box mr={5} ml={5}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  textAlign="center"
                  alignItems="center"
                  p={2}
                >
                  <Box>
                    <Typography variant="body1" color="textPrimary">
                      52
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      Tasks
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" color="textPrimary">
                      04
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      Bugs
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                textAlign="center"
                alignItems="start"
                p={2}
              >
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    To Do
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    52
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Ongoing
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    20
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Review
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    03
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Done
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    29
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignContent="center"
                textAlign="end"
                justifyContent="space-between"
                p={2}
              >
                <Box textAlign="center">
                  <Typography variant="body2">High Priority</Typography>
                  <Typography variant="h6" color="error">
                    04
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Due date
                  </Typography>
                  <Typography variant="h6">
                    {moment(data?.estimatedEndDate).format("MMM DD, Y")}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
