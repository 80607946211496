import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box, Divider, List, ListItem } from "@material-ui/core";
import { CustomTextField } from "components/FormFields/CustomTextField";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Members({
  anchorEl,
  setAnchorEl,
  state,
  setState,
  members,
}) {
  const [query, setQuery] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box px={2}>
          <CustomTextField
            name="search"
            placeholder="Search Members"
            onChange={handleChange}
          />
          <Box my={1}>
            <Divider />
          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              style={{ textTransform: "uppercase" }}
            >
              Board Members
            </Typography>
            <List>
              {members
                .filter(
                  (m) =>
                    m.firstName.toLowerCase().indexOf(query.toLowerCase()) > -1
                )
                .map((item) => (
                  <ListItem
                    button
                    onClick={() => {
                      setState({
                        ...state,
                        userFirstName: item.firstName,
                        user: item.id,
                      });
                    }}
                  >
                    {item.firstName}
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
