import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { TypePredicateKind } from "typescript";
import moment from "moment";

function Dashboard() {
  const [state, setState] = useState({
    status: "Active",
    activeTime: "5hrs",
    firstName: "Srikanth",
    lastName: "Yellapragada",
  });

  return (
    <Grid container>
      <Grid xs={12}>
        <Box display="flex" justifyContent="space-between" my={2}>
          <Typography variant="h6">Dashboard</Typography>
          <Typography variant="h6">
            {moment().format("DD MMM, YYYY")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <Typography variant="subtitle1">Welcome</Typography>
          <Typography variant="h6">
            {state?.firstName} {state?.lastName}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1">Active Time</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">Status</Typography>
            <Typography variant="h6">{state.status}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box style={{ float: "right" }}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: "2px" }}
          >
            Check In
          </Button>

          <Button variant="outlined" color="primary">
            Request Leave
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box p={2} justifyContent="space-between" display="flex">
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1">Welcome</Typography>
            <Typography variant="h6">
              {state?.firstName} {state?.lastName}
            </Typography>
          </Box>
          <Box px={2}>
            <Typography variant="subtitle1">Active</Typography>
            <Typography variant="h6">Srikanth Yellapragada</Typography>
          </Box>
          <Box px={2}>
            <Typography variant="subtitle1">Working</Typography>
            <Typography variant="h6">Srikanth Yellapragada</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: "2px" }}
        >
          Check In
        </Button>

        <Button variant="outlined" color="primary">
          Request Leave
        </Button>
      </Box>
    </Box>
  );
}

export default Dashboard;
