import development from './development';
import production from './production';
const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV;
let cfg = {};
if (ENV == 'development') {
  cfg = development;
} else if (ENV == 'production') {
  cfg = production;
}
export default cfg;
