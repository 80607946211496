import { CircularProgress, Fab, Grid, makeStyles } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { getProjects } from "api/ApiService";
import React, { useEffect, useState } from "react";
import CreateProject from "./components/CreateProject";
import ProjectCard from "./components/ProjectCard";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function Projects() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = useState({ data: [], loading: false });
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setProjects({ ...projects, loading: true });
    try {
      let res = await getProjects();
      setProjects({ data: res.data, loading: false });
    } catch (err) {
      console.log(`cannot get projects due to ${err}`);
    }
  }
  if (projects.loading) return <CircularProgress />;

  return (
    <>
      <Grid container spacing={3}>
        {projects.data.map((item) => (
          <Grid item lg={4} xs={12} md={6}>
            <Link
              to={`projects/${item?.slug}`}
              style={{ textDecoration: "none" }}
            >
              <ProjectCard data={item} />
            </Link>
          </Grid>
        ))}
      </Grid>

      <Fab
        color="primary"
        className={classes.fab}
        aria-label="add"
        onClick={() => setOpen(true)}
      >
        <AddOutlinedIcon />
      </Fab>
      <CreateProject open={open} setOpen={setOpen} getData={getData} />
    </>
  );
}

export default Projects;
