import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box, Divider, List, ListItem } from "@material-ui/core";
import { CustomTextField } from "components/FormFields/CustomTextField";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function DueDate({ anchorEl, setAnchorEl, state, setState }) {
  const classes = useStyles();
  const [dueDate, setDueDate] = useState();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hangleChange = (e) => {
    setDueDate(e.target.value);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box px={3}>
          <Box my={2}>
            <Typography
              variant="subtitle1"
              style={{ textTransform: "uppercase" }}
            >
              Due Date
            </Typography>
            <CustomTextField
              type="date"
              onChange={hangleChange}
              value={dueDate}
            ></CustomTextField>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setState({ ...state, dueDate: dueDate });
                handleClose();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
