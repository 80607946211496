import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { React, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import EditUser from "./EditUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  avatar: {
    textTransform: "uppercase",
    color: "whitesmoke",
    backgroundColor: "#673ab7",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function UserCard({ data, getData }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [editOpen, setEditOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box style={{ float: "right" }}>
                <IconButton style={{ padding: "0px" }} onClick={handleClick}>
                  <EditIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setEditOpen(true);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>Delete</MenuItem>
                </Menu>
              </Box>
              <Box p={2}>
                <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar className={classes.avatar}>
                    {data?.firstName.charAt(0)}
                    {data?.lastName.charAt(0)}
                  </Avatar>
                </Box>
                <Box
                  display="flex"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h6"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data?.firstName} {data?.lastName}
                  </Typography>
                  {data?.isSuperUser ? (
                    <SupervisedUserCircleOutlinedIcon />
                  ) : null}
                </Box>
              </Box>
              <Divider />
              <Box mr={5} ml={5}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  textAlign="center"
                  alignItems="center"
                  p={2}
                >
                  <Box>
                    <Typography variant="body1" color="textPrimary">
                      2
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      Projects
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" color="textPrimary">
                      124
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      Tasks
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                textAlign="center"
                alignItems="start"
                p={2}
              >
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    To Do
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    52
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Ongoing
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    20
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Review
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    03
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="textPrimary">
                    Done
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    29
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignContent="center"
                textAlign="end"
                justifyContent="space-between"
                p={2}
              >
                <Box textAlign="center">
                  <Typography variant="body2">Leaves</Typography>
                  <Typography variant="h6" color="error">
                    04
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="body2">Points Earned</Typography>
                  <Typography variant="h6" color="success">
                    1024
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <EditUser
        open={editOpen}
        setOpen={setEditOpen}
        userData={data}
        getData={getData}
      />
    </div>
  );
}
