import {
  CircularProgress,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { getMyTasks, updateTask } from "api/ApiService";
import EditTask from "components/Tasks/components/EditTask";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import Task from "./Task";

const ListWrapper = styled(List)`
  display: inline-block;
  vertical-align: top;
  height: 100%;
  margin: 0px 10px;
  border: 1px solid lightgrey;
  width: 300px;
`;

const ListTitle = styled(Typography)`
  display: inherit;
  text-align: center;
`;

const columns = [
  { id: "toDo", title: "To do" },
  { id: "inProgress", title: "In Progress" },
  { id: "done", title: "Done" },
];

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

const useStyles = makeStyles((theme) => ({}));

function TaskView() {
  const [tasks, setTasks] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setTasks({ data: [], loading: true });
    try {
      let userId = localStorage.getItem("userId");
      let res = await getMyTasks(userId);
      setTasks({ data: res.data, loading: false });
    } catch (e) {
      console.log("server error");
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }

    let sourceList = tasks.data.filter(
      (task) => task.status == result.source.droppableId
    );
    let destinationList = tasks.data.filter(
      (task) => task.status == result.destination.droppableId
    );

    let list = reorder(
      tasks.data,
      result.source.index,
      result.destination.index
    );
    setTasks({ data: list });

    let task = tasks.data.find((task) => task.id == result.draggableId);

    updateTask(result.draggableId, {
      ...task,
      status: result.destination.droppableId,
    })
      .then(() => {
        list.map((task) => {
          if (task.id == result.draggableId) {
            task.status = result.destination.droppableId;
            setTasks({ data: list });
          }
        });
      })
      .catch((e) => {});
  }

  if (tasks.loading) return <CircularProgress />;

  return (
    <div style={{ display: "flex" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {columns.map((column) => {
          let items = _.filter(tasks.data, ["status", column.id]);
          return (
            <ListWrapper>
              <ListTitle>{column.title}</ListTitle>
              <Droppable droppableId={column.id}>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Task task={item} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </ListWrapper>
          );
        })}
      </DragDropContext>
      {/* <EditTask /> */}
    </div>
  );
}

export default TaskView;
