import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CustomTextField } from "components/FormFields/CustomTextField";
import React, { useEffect, useRef, useState } from "react";
import Labels from "./CreateTask/Labels";
import Members from "./CreateTask/Members";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DueDate from "./CreateTask/DueDate";
import moment from "moment";
import Points from "./CreateTask/Points";
import { createTask, getMembers, updateTask } from "api/ApiService";
import { useSnackbar } from "notistack";
import Type from "./CreateTask/Type";

export default function EditTask({ open, setOpen, task }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const membersRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };
  const [itemInput, setItemInput] = useState("");
  const [state, setState] = useState({
    user: task.user,
    userFirstName: "",
    labels: task.labels,
    checklist: task.checklist,
    dueDate: task.dueDate,
    project: task.project,
    points: task.points,
    type: task.type,
    title: task.title,
    description: task.description,
  });

  const [userEl, setUserEl] = React.useState(null);
  const [labelsEl, setLabelEl] = React.useState(null);
  const [dueDateEl, setDueDateEl] = React.useState(null);
  const [pointsEl, setPointsEl] = React.useState(null);
  const [members, setMembers] = React.useState([]);
  const [typeEl, setTypeEl] = React.useState(null);

  useEffect(() => {
    getMembersData();
  }, []);

  async function getMembersData() {
    try {
      let res = await getMembers();
      setMembers(res.data);
    } catch (err) {
      console.log(`cannot get memebers due to server error ${err.message}`);
    }
  }

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  function handleLabelDelete(index) {
    let filteredChips = state.labels.filter((m, i) => i !== index);
    setState({ ...state, labels: filteredChips });
  }

  function handleCheckItemChange(index) {
    let changedState = { ...state };
    changedState.checklist.items[index].checked = !changedState.checklist.items[
      index
    ].checked;
    setState(changedState);
  }

  function handleCheckItemDelete(index) {
    let newItems = state.checklist.items.filter((item, i) => i !== index);
    setState({ ...state, checklist: { ...state.checklist, items: newItems } });
  }

  function addItemToChecklist() {
    if (!itemInput) return;
    setState({
      ...state,
      checklist: {
        ...state.checklist,
        items: [
          ...state.checklist.items,
          {
            title: itemInput,
            checked: false,
          },
        ],
      },
    });
    setItemInput("");
  }

  function handleItemInputChange(e) {
    setItemInput(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    updateTask(task.id, state)
      .then(() => {
        enqueueSnackbar("Task has been updated successfully, Happy Coding!", {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error updating task due to server error ${err.message}`,
          { variant: "danger" }
        );
      });
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Task</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <CustomTextField
                name="title"
                label="Title"
                value={state.title}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <CustomTextField
                    name="description"
                    label="Description"
                    value={state.description}
                    onChange={handleChange}
                    required
                    multiline
                    rows="5"
                    InputLabelProps={{ shrink: true }}
                  />

                  <Grid container>
                    {state.user ? (
                      <Grid item xs={4}>
                        <Box mt={1}>
                          <Typography variant="subtitle1" color="textSecondary">
                            User Assigned
                          </Typography>
                          <Chip
                            color="primary"
                            style={{ margin: "2px" }}
                            label={state.userFirstName}
                            onDelete={() => setState({ ...state, user: null })}
                          />
                        </Box>
                      </Grid>
                    ) : null}
                    <Grid item xs={8}>
                      {state.labels.length ? (
                        <Box mt={1}>
                          <Typography variant="subtitle1" color="textSecondary">
                            Labels
                          </Typography>
                          {state.labels.map((item, index) => (
                            <Chip
                              color="primary"
                              style={{ margin: "2px" }}
                              label={item}
                              onDelete={() => handleLabelDelete(index)}
                            />
                          ))}
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container>
                    {state.dueDate ? (
                      <Grid item xs={4}>
                        <Box mt={1}>
                          <Typography variant="subtitle1" color="textSecondary">
                            Due Date
                          </Typography>
                        </Box>

                        <Chip
                          color="primary"
                          style={{ margin: "2px" }}
                          label={moment(state.dueDate).format("MMM DD, Y")}
                          onDelete={() => {
                            setState({ ...state, dueDate: "" });
                          }}
                        />
                      </Grid>
                    ) : null}

                    {state.type !== "bug" && state.points > 0 ? (
                      <Grid item xs={4}>
                        <Box mt={1}>
                          <Typography variant="subtitle1" color="textSecondary">
                            Points
                          </Typography>

                          <Chip
                            color="primary"
                            style={{ margin: "2px" }}
                            label={state.points}
                            onDelete={() => {
                              setState({ ...state, points: 0 });
                            }}
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    {state.type ? (
                      <Grid item xs={4}>
                        <Box mt={1}>
                          <Typography variant="subtitle1" color="textSecondary">
                            Type
                          </Typography>

                          <Chip
                            color="primary"
                            style={{ margin: "2px" }}
                            label={state.type}
                            onDelete={() => {
                              setState({ ...state, type: "" });
                            }}
                          />
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>

                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: 5 }}
                    color="textSecondary"
                  >
                    {state.checklist.name}
                  </Typography>
                  {state.checklist.items.map((checkItem, index) => (
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkItem.checked}
                            onChange={() => handleCheckItemChange(index)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={checkItem.title}
                      />
                      <IconButton onClick={() => handleCheckItemDelete(index)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  ))}

                  <CustomTextField
                    placeholder="Add Item"
                    value={itemInput}
                    onChange={handleItemInputChange}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={addItemToChecklist}
                  >
                    Add Item
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Box
                    pl={4}
                    textAlign="right"
                    justifyContent="end"
                    display="flex"
                    flexDirection="column"
                    gridGap={10}
                  >
                    <Typography variant="body1" color="textSecondary">
                      Add to Card
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setUserEl(e.target)}
                      ref={membersRef}
                    >
                      Members
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setLabelEl(e.target)}
                    >
                      Labels
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setDueDateEl(e.target)}
                    >
                      Due Date
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setUserEl(e.target)}
                    >
                      Attachment
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setPointsEl(e.target)}
                    >
                      Points
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setTypeEl(e.target)}
                    >
                      Type
                    </Button>
                  </Box>
                  <Members
                    anchorEl={userEl}
                    setAnchorEl={setUserEl}
                    state={state}
                    setState={setState}
                    members={members}
                  />
                  <Labels
                    anchorEl={labelsEl}
                    setAnchorEl={setLabelEl}
                    state={state}
                    setState={setState}
                  />
                  <DueDate
                    anchorEl={dueDateEl}
                    setAnchorEl={setDueDateEl}
                    state={state}
                    setState={setState}
                  />
                  <Points
                    anchorEl={pointsEl}
                    setAnchorEl={setPointsEl}
                    state={state}
                    setState={setState}
                  />
                  <Type
                    anchorEl={typeEl}
                    setAnchorEl={setTypeEl}
                    state={state}
                    setState={setState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
